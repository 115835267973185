export const validateEmail = (email) => {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@(([^<>()[\]\\.,;:\s@"]+\.)+[^<>()[\]\\.,;:\s@"]{2,})$/i;
  return re.test(String(email).toLowerCase());
};

export const validatePin = (pin) => {
  // This regex is for a 6-digit pin code, not starting with 0.
  const re = /^[1-8][0-9]{5}$/;
  return re.test(pin);
};

export const validateMobile = (number) => {
  const re = /^\d{10}$/; // Validates the ten digits after +91
  return re.test(number);
};
