import * as React from "react";
import { Grid, OutlinedInput } from "@mui/material";
import { BLACK_SHADE } from "../theme/constants";
import { getIcon } from "../utils/getIcon";

const CustomInput = ({ id, defaultValue, onBlur, label }) => (
  <Grid item xs={12} md={6} key={id}>
    <OutlinedInput
      variant="outlined"
      className={`custom-input-text`}
      placeholder={label}
      startAdornment={getIcon(id, BLACK_SHADE, "24px", "white")}
      fullWidth
      type="text"
      sx={{
        borderColor: "#667085",
        color: "#667085",
        "&.Mui-focused": {
          color: "#0fabbb !important",
          borderColor: "#0fabbb !important",
          backgroundColor: "#f1f9fc !important",
          outlineColor: "#0fabbb !important",
          "& fieldset": {
            borderColor: "#0fabbb !important",
          },
        },
        ":hover": {
          color: "#0fabbb",
          borderColor: "#0fabbb",
          backgroundColor: "#f1f9fc",
          outlineColor: "#0fabbb",
          "& fieldset": {
            borderColor: "#0fabbb !important",
          },
        },
        "& .MuiInputBase-input::placeholder": {
          color: "#667085",
          opacity: 1,
        },
        "& fieldset": {
          borderColor: "#667085",
        },
      }}
      onBlur={onBlur}
      defaultValue={defaultValue}
    />
  </Grid>
);

export { CustomInput };
