import {
  Button,
  Box,
  FormControl,
  Typography,
  Grid,
  FormHelperText,
  OutlinedInput,
} from "@mui/material";
import { useState, useEffect } from "react";
import { verifyOtp, resendOtp, handleEstimation } from "../data/api";
import { PRIMARY } from "../theme/constants";
import CheckCircleRoundedIcon from "@mui/icons-material/CheckCircleRounded";
import { BackButton } from "../components/back-button";
import { ResendLink } from "../components/resend-link";
import OTPInput from "../components/otp-input";

const VerifyOtp = ({
  activeStep,
  updateActiveStep,
  userResponse,
  updateShowFinalEstimationScreen,
  updateShowVerifyOtpPage,
}) => {
  const [phoneNumber, setPhoneNumber] = useState(
    userResponse["user-details"]?.["phone-no"] ?? ""
  );
  const [otpSent, setOtpSent] = useState(false);
  const [countdown, setCountdown] = useState(30);
  const [resendDisabled, setResendDisabled] = useState(false);
  // State to manage OTP input
  const [otp] = useState("");

  useEffect(() => {
    let intervalId;

    if (resendDisabled && countdown > 0) {
      intervalId = setInterval(() => {
        setCountdown((currentCountdown) => currentCountdown - 1);
      }, 1000);
    } else if (!resendDisabled) {
      setCountdown(30); // Reset countdown when resend is enabled
    }

    return () => clearInterval(intervalId); // Cleanup interval on component unmount
  }, [resendDisabled, countdown]);

  // Function to handle OTP verification
  const handleVerifyOtp = async (event) => {
    event.preventDefault();
    try {
      const response = await verifyOtp(otp, phoneNumber);
      if (response.type === "success") {
        await handleEstimation(userResponse);
        updateShowFinalEstimationScreen(true);
      } else {
        // Log the error message for debugging purposes
        console.error("Verification unsuccessful:", response.message);
      }
    } catch (error) {
      console.error("Error verifying OTP:", error);
    }
  };

  const handleResendOtp = () => {
    resendOtp(phoneNumber)
      .then(() => {
        console.log("OTP resent successfully");
        setResendDisabled(true);
        setTimeout(() => setResendDisabled(false), 30000); // Re-enable the resend button after 30 seconds
      })
      .catch((error) => {
        console.error("Error resending OTP", error);
      });
  };

  return (
    <>
      {otpSent ? (
        <FormControl className="user-form verify-otp-form">
          <Box sx={{ display: "flex", alignItems: "center", gap: "4px" }}>
            <BackButton
              onClick={() => {
                updateActiveStep(activeStep - 1);
                updateShowVerifyOtpPage(false);
              }}
            />
            <Typography className="verify-otp-text">Enter OTP</Typography>
          </Box>
          <Typography className="verify-otp-helper-text">
            We've sent you an OTP on your Whatsapp number {phoneNumber}
          </Typography>
          <Grid container spacing={2.5}>
            <OTPInput />
          </Grid>
          <Box className="form-action-panel">
            <Button
              variant="contained"
              className="continue-btn get-otp-btn"
              onClick={handleVerifyOtp}
            >
              Download PDF
            </Button>
          </Box>
          <FormHelperText sx={{ display: "flex", alignItems: "center" }}>
            Didn't receive OTP?
            <ResendLink onClick={handleResendOtp} label={"Resend"} />
          </FormHelperText>
        </FormControl>
      ) : (
        <FormControl className="user-form verify-otp-form">
          <Box sx={{ display: "flex", alignItems: "center", gap: "4px" }}>
            <BackButton
              onClick={() => {
                updateActiveStep(activeStep - 1);
                updateShowVerifyOtpPage(false);
              }}
            />
            <Typography className="verify-otp-text">Verify Yourself</Typography>
          </Box>
          <Grid container spacing={2.5}>
            <Grid item xs={12} md={12} key={"otp-input"}>
              <FormControl variant="standard" className="otp-input-control">
                <FormHelperText className="otp-input-text">
                  Phone no.
                </FormHelperText>
                <OutlinedInput
                  className="phone-input"
                  id={"phone-number"}
                  type={"number"}
                  value={phoneNumber}
                  onChange={(e) => setPhoneNumber(e.target.value)}
                />
                <FormHelperText sx={{ display: "flex", alignItems: "center" }}>
                  <CheckCircleRoundedIcon
                    color={PRIMARY}
                    sx={{
                      fontSize: "14px",
                      padding: "0 4px",
                      color: PRIMARY,
                    }}
                  />
                  This is my whatsapp number
                </FormHelperText>
                <FormHelperText className="confirmation-text">
                  We will use this no. to send you the detailed PDF. Stay
                  assured we send important updates only.
                </FormHelperText>
              </FormControl>
            </Grid>
          </Grid>
          <Box className="form-action-panel">
            <Button
              variant="contained"
              className="continue-btn get-otp-btn"
              onClick={() => setOtpSent(true)}
            >
              Get OTP
            </Button>
          </Box>
        </FormControl>
      )}
    </>
  );
};

export default VerifyOtp;
