import { FormControl, Typography } from "@mui/material";
import HoldOnGif from "../assets/hold-on.gif";
import { useState, useEffect } from "react";
import { verifyPhoneNumber } from "../data/api";
import { ResendLink } from "../components/resend-link";

function maskPhoneNumber(phoneNumber) {
  const lastFourDigits = phoneNumber.slice(-4);
  const maskLength = phoneNumber.length - 4;
  const maskedSection = "*".repeat(maskLength);
  return maskedSection + lastFourDigits;
}

const LoadingScreen = ({
  phoneNumber,
  updateShowFinalEstimationScreen,
  updateShowLoadingScreen,
  updateShowVerifyOtpPage,
}) => {
  const [isNumberVerified, setIsNumberVerified] = useState(false);

  useEffect(() => {
    let intervalId;
    if (!isNumberVerified && phoneNumber) {
      intervalId = setInterval(() => {
        verifyPhoneNumber("91" + phoneNumber)
          .then((response) => {
            if (response.type === "success") {
              setIsNumberVerified(true); // Set the number as verified on success
              updateShowLoadingScreen(false);
              updateShowFinalEstimationScreen(true);
            }
          })
          .catch((error) => {
            console.error("Error verifying phone number:", error);
          });
      }, 3000); // Retry every 3 seconds
    }

    return () => {
      if (intervalId) clearInterval(intervalId); // Clear interval on component unmount or when phoneNumber changes or is verified
    };
  }, [phoneNumber, isNumberVerified, updateShowFinalEstimationScreen, updateShowLoadingScreen]);

  return (
    <FormControl
      className="user-form"
      sx={{
        width: "50% !important",
      }}
    >
      <img
        src={HoldOnGif}
        height={250}
        width={250}
        style={{ alignSelf: "center" }}
        alt={"Hold On"}
      />
      <Typography className="hold-on-text">
        Hold On
        <div class="dot" id="dot1"></div>
        <div class="dot" id="dot2"></div>
        <div class="dot" id="dot3"></div>
      </Typography>
      <Typography className="hold-on-helper-text">
        We are trying to auto establish communication on Whatsapp. Once verified
        you'll get the PDF on {maskPhoneNumber(phoneNumber)}.
      </Typography>
      <Typography className="hold-on-sub-helper-text">
        Don't have Whatsapp with this number?
        <ResendLink
          label={"Verify Via SMS"}
          onClick={() => {
            updateShowLoadingScreen(false);
            updateShowVerifyOtpPage(true);
          }}
        />
      </Typography>
    </FormControl>
  );
};

export default LoadingScreen;
