import { useState, useEffect } from "react";

export const ResendLink = ({ onClick, label }) => {
  const [seconds, setSeconds] = useState(30);
  const [isResendLinkDisabled, setIsResendLinkDisabled] = useState(true);

  useEffect(() => {
    if (seconds === 0) {
      setIsResendLinkDisabled(false);
      return;
    }

    const intervalId = setInterval(() => {
      setSeconds(seconds - 1);
    }, 1000);

    return () => clearInterval(intervalId);
  }, [seconds]);

  return (
    <button
      disabled={isResendLinkDisabled}
      className={isResendLinkDisabled ? "resend-link disabled" : "resend-link"}
      onClick={onClick}
    >
      {seconds > 0 ? `${label}(${seconds}s)` : `${label}`}
    </button>
  );
};
