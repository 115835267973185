import formsData from './forms.json';

export const getFormData = (formID) => {
  return formsData[formID];
};

export const QuesList = [
    {
      id: "your-smart-home",
      question: "Which smart home capabilities are you looking to integrate?",
      summaryText: "Your Smart Home",
      label: "Select multiple categories",
      btnText: "Continue",
      btnIcon: true,
      optionType: "button",
      multiSelect: true,
      options: [
        {
          id: "smart-lighting",
          text: "Smart Lighting",
        },
        {
          id: "multimedia-control",
          text: "Multimedia Control",
        },
        {
          id: "accident-protection",
          text: "Accident Protection",
        },
        {
          id: "voice-command",
          text: "Voice Command",
        },
        {
          id: "home-security",
          text: "Home Security",
        },
        {
          id: "climate-control",
          text: "Climate Control",
        },
        {
          id: "surveillance",
          text: "Surveillance",
        },
      ]
    },
    {
      id: "home-size",
      question: "Type of home do you have?",
      summaryText: "Home Size",
      label: "Select an option",
      btnText: "Continue",
      btnIcon: true,
      optionType: "button",
      options: [
        {
          id: "1bhk",
          text: "1BHK",
          buttons: [
            {
              id: "small",
              text: "Small",
              subText: "Below 800 Sq. Ft."
            },
            {
              id: "large",
              text: "Large",
              subText: "Above 800 Sq. Ft."
            }
          ]
        },
        {
          id: "2bhk",
          text: "2BHK",
          buttons: [
            {
              id: "small",
              text: "Small",
              subText: "Below 1000 Sq. Ft."
            },
            {
              id: "large",
              text: "Large",
              subText: "Above 1000 Sq. Ft."
            }
          ]
        },
        {
          id: "3bhk",
          text: "3BHK",
          buttons: [
            {
              id: "small",
              text: "Small",
              subText: "Below 1400 Sq. Ft."
            },
            {
              id: "large",
              text: "Large",
              subText: "Above 1400 Sq. Ft."
            }
          ]
        },
        {
          id: "4bhk",
          text: "4BHK",
          buttons: [
            {
              id: "small",
              text: "Small",
              subText: "Below 1700 Sq. Ft."
            },
            {
              id: "large",
              text: "Large",
              subText: "Above 1700 Sq. Ft."
            }
          ]
        },
        {
          id: "custom",
          text: "Custom",
        },
      ]
    },
    {
      id: "solution-type",
      question: "Which type of wireless solution do you prefer?",
      summaryText: "Solution Type",
      label: "Select an option",
      btnText: "Continue",
      btnIcon: true,
      optionType: "button",
      options: [
        {
          id: "flexible-economical",
          text: "Flexible and Economical",
          subText: "Get a perfect smart home solution from smart home technology experts. "
        },
        {
          id: "premium-robust",
          text: "Premium and Robust",
          subText: "Get a perfect smart home solution from smart home technology experts. "
        },
        {
          id: "high-end-futuristic",
          text: "High-end and Futuristic",
          subText: "Get a perfect smart home solution from smart home technology experts. "
        },
      ]
    },
    {
      id: "home-stage",
      question: "What stage is your home?",
      summaryText: "Home Stage",
      label: "Select an option",
      btnText: "Continue",
      btnIcon: true,
      optionType: "button",
      options: [
        {
          id: "floor-plan",
          text: "I Have a Floor Plan"
        },
        {
          id: "under-construction",
          text: "Under Construction",
        },
        {
          id: "constructed",
          text: "Constructed",
        },
        {
          id: "moved-in",
          text: "Moved In",
        },
      ]
    },
    {
      id: "completion-date",
      question: "By When will the construction finish?",
      summaryText: "Completion Date",
      label: "Select an option",
      btnText: "Continue",
      btnIcon: true,
      optionType: "button",
      options: [
        {
          id: "one-month",
          text: "Within 1 Month"
        },
        {
          id: "six-month",
          text: "Within 6 Months",
        },
        {
          id: "one-year",
          text: "Within 1 Year",
        },
        {
          id: "custom",
          text: "Custom",
        },
      ]
    },
    {
      id: "user-details",
      question: "Your smart home estimate is almost ready: ₹ XX,XXX",
      summaryText: "",
      label: "You are just one step away from download a free estimate.",
      btnText: "Send OTP on Whatsapp",
      btnIcon: true,
      optionType: "text-input",
      options: [
        {
          id: "name",
          text: "Name",
        },
        {
          id: "email",
          text: "Email (optional)"
        },
        {
          id: "area-pin",
          text: "Area PIN (helps in finding the nearest partner)",
        },
        {
          id: "phone-no",
          text: "Phone no.",
          subText: "Share an estimate copy on WhatsApp"
        },
      ]
    },
  ];
