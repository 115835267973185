import { FormControl, Grid } from "@mui/material";
import { getFormData } from "../data";
import FormFooter from "../components/form-footer";
import { QuestionText, QuestionHelperText } from "../components/question-text";
import { FormInput } from "../components/form-input";

const SmartHomeCapabilities = ({
  activeStep,
  updateActiveStep,
  userResponse,
  updateUserResponse,
  formID
}) => {
  const formData = getFormData(formID);
  const { question, label, options, id } = formData.questions[activeStep];

  return (
    <FormControl className="question-form">
      <QuestionText text={question} />
      <QuestionHelperText text={label} />
      <Grid container spacing={2.5}>
        {options.map((btn) => {
          const selectedClass =
            userResponse[id].length > 0 && userResponse[id].includes(btn.id)
              ? "selected"
              : "";
          return (
            <FormInput
              key={btn.id}
              id={btn.id}
              selectedClass={selectedClass}
              onClick={() => {
                if (userResponse[id].includes(btn.id)) {
                  userResponse[id] = userResponse[id].filter(
                    (ans) => ans !== btn.id
                  );
                } else {
                  userResponse[id].push(btn.id);
                }
                updateUserResponse({ ...userResponse });
              }}
              label={btn.text}
            />
          );
        })}
      </Grid>
      <FormFooter activeStep={activeStep} updateActiveStep={updateActiveStep} />
    </FormControl>
  );
};

export default SmartHomeCapabilities;
