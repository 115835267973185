import {
  Button,
  Box,
  FormControl,
  Typography,
  Grid,
  FormHelperText,
  OutlinedInput,
  InputAdornment,
  Hidden,
} from "@mui/material";
import { getFormData } from "../data";
import { PRIMARY } from "../theme/constants";
import CheckCircleRoundedIcon from "@mui/icons-material/CheckCircleRounded";
import { getIcon } from "../utils/getIcon";
import { handleEstimation } from "../data/api";
import {
  validateEmail,
  validatePin,
  validateMobile,
} from "../utils/validation";
import { QuestionHelperText } from "../components/question-text";
import { BackButton } from "../components/back-button";

const UserDetails = ({
  activeStep,
  updateActiveStep,
  userResponse,
  updateUserResponse,
  updateShowLoadingScreen,
  formID
}) => {
  const formData = getFormData(formID);
  const { label, btnText, options, id } = formData.questions[activeStep];

  const isButtonDisabled = () => {
    const enteredValues = userResponse[id];
    if (
      !enteredValues.name ||
      !enteredValues["phone-no"] ||
      !enteredValues.email ||
      !enteredValues["area-pin"]
    ) {
      return true;
    }
    // Ensure all values are validated before enabling the button
    return (
      handleValidation("email", enteredValues.email) ||
      handleValidation("phone-no", enteredValues["phone-no"]) ||
      handleValidation("area-pin", enteredValues["area-pin"])
    );
  };

  const handleValidation = (validationType, value) => {
    if (value) {
      if (validationType === "email") {
        return !validateEmail(value);
      } else if (validationType === "area-pin") {
        return !validatePin(value);
      } else if (validationType === "phone-no") {
        return !validateMobile(value);
      }
      return false;
    }
    return false;
  };

  const handleNextStep = () => {
    handleEstimation(userResponse);
    updateShowLoadingScreen(true);
    updateActiveStep(activeStep + 1);
    window.open("https://wa.me/message/PPHGW2OAPSBWL1", "_blank");
    //    window.open("https://wa.me/message/2KEKKOSORE2EC1", "_blank");
  };

  return (
    <FormControl className="user-form">
      <Hidden smUp>
        <BackButton onClick={() => updateActiveStep(activeStep - 1)} />
      </Hidden>
      <Typography className="user-detail-text">
        <Hidden smDown>Your smart home estimate is almost ready: </Hidden>
        <Hidden smUp>
          Your smart home estimate is: <br />
        </Hidden>
        <span style={{ color: PRIMARY }}> ₹XX,XXX</span>
      </Typography>
      <QuestionHelperText text={label} />
      <Grid container spacing={2.5}>
        {options.map((field) => {
          return (
            <Grid item xs={12} md={6} key={field.id}>
              <FormControl variant="standard" sx={{ width: "100%" }}>
                <FormHelperText id="outlined-weight-helper-text">
                  {field.text}
                </FormHelperText>
                <OutlinedInput
                  id={field.id}
                  fullWidth
                  type={
                    field.id === "area-pin" || field.id === "phone-no"
                      ? "tel"
                      : "text"
                  }
                  value={userResponse[id]?.[field.id] || ""} // Ensure the input is controlled by setting its value from the userResponse state
                  onChange={(e) => {
                    userResponse[id] = {
                      ...userResponse[id],
                      [field.id]: e.target.value,
                    };
                    updateUserResponse({ ...userResponse });
                  }}
                  error={handleValidation(
                    field.id,
                    userResponse[id]?.[field.id]
                  )}
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                        borderColor: PRIMARY, // Change to use PRIMARY color
                      },
                    },
                    "&.Mui-focused": {
                      borderColor: "#0fabbb !important",
                      backgroundColor: "#f1f9fc !important",
                      outlineColor: "#0fabbb !important",
                      "& fieldset": {
                        borderColor: "#0fabbb !important",
                      },
                    },
                    "&:hover .MuiOutlinedInput-notchedOutline": {
                      borderColor: PRIMARY, // Optional: Change border color on hover
                    },
                  }}
                  startAdornment={
                    field.id === "phone-no" ? <InputAdornment position="start">+91</InputAdornment> : null
                  }
                />
                {field.subText ? (
                  <FormHelperText
                    sx={{ display: "flex", alignItems: "center" }}
                  >
                    <CheckCircleRoundedIcon
                      color={PRIMARY}
                      sx={{
                        fontSize: "14px",
                        padding: "0 4px",
                        color: PRIMARY,
                      }}
                    />
                    {field.subText}
                  </FormHelperText>
                ) : null}
              </FormControl>
            </Grid>
          );
        })}
      </Grid>

      <Hidden smDown>
        <Box className="form-action-panel">
          <BackButton onClick={() => updateActiveStep(activeStep - 1)} />
          <Button
            variant="contained"
            className="submit-btn"
            startIcon={getIcon("whatsapp-logo")}
            disabled={isButtonDisabled()}
            onClick={handleNextStep}
          >
            {btnText}
          </Button>
        </Box>
      </Hidden>
      <Hidden smUp>
        <Box className="form-action-panel">
          <Button
            variant="contained"
            className="submit-btn"
            startIcon={getIcon("whatsapp-logo")}
            disabled={isButtonDisabled()}
            onClick={handleNextStep}
            fullWidth
          >
            Verify Via Whatsapp
          </Button>
        </Box>
      </Hidden>
    </FormControl>
  );
};

export default UserDetails;
