import { Typography, Box, Card } from "@mui/material";
import { BLACK_SHADE, PRIMARY, GREY_4 } from "../theme/constants";
import { getIcon } from "../utils/getIcon";
import { QuesList } from "../data";

const Summary = ({ userResponse }) => {
  return (
    <Box className="summary-container">
      <div className="summary-title">
        {getIcon("summary-home", PRIMARY, "32px")}
        <Typography className="question-text" sx={{ color: BLACK_SHADE }}>
          Smart Summary
        </Typography>
      </div>
      {QuesList.map((que) => {
        if (que.multiSelect) {
          if (userResponse[que.id]?.length) {
            const selectedOption = que.options.filter((q) =>
              userResponse[que.id].includes(q.id)
            );
            return (
              <div key={que.id}>
                <Card
                  className="summary-section-title"
                  sx={{ backgroundColor: GREY_4, color: BLACK_SHADE }}
                >
                  {que.summaryText}
                </Card>
                {selectedOption.map((opt) => {
                  return (
                    <Box className="summary-section-item" key={opt.id}>
                      {getIcon(opt.id, PRIMARY, "24px", PRIMARY)}
                      <Typography
                        className="summary-section-item-text"
                        sx={{ color: BLACK_SHADE }}
                      >
                        {opt.text}
                      </Typography>
                    </Box>
                  );
                })}
              </div>
            );
          }
        } 
        else if (que.id === "completion-date") {
          if (userResponse[que.id]) {
            const selectedOption = que.options.find(
              (q) => q.id === userResponse[que.id]?.type
            );
            const summaryText = userResponse[que.id]?.date
            return (
              <div key={que.id}>
                <Card
                  className="summary-section-title"
                  sx={{ backgroundColor: GREY_4, color: BLACK_SHADE }}
                >
                  {que.summaryText}
                </Card>
                <Box className="summary-section-item">
                  {getIcon(selectedOption.id, PRIMARY)}
                  <Typography
                    className="summary-section-item-text"
                    sx={{ color: BLACK_SHADE }}
                  >
                    {summaryText}
                  </Typography>
                </Box>
              </div>
            );
          }
        }
        else {
          if (que.id !== "user-details") {
            if (userResponse[que.id]) {
              const selectedOption = que.options.find(
                (q) =>
                  q.id === userResponse[que.id] || q.id === userResponse[que.id]?.type
              );
              const summaryText =
                typeof userResponse[que.id] === "object"
                  ? `${selectedOption.text} ${
                      selectedOption.buttons ? ` - ( ${selectedOption.buttons.find(
                        (x) => x.id === userResponse[que.id].size
                      ).subText} )` : ` - ( ${userResponse[que.id].size} )`
                    }`
                  : selectedOption?.text;
              return (
                <div key={que.id}>
                  <Card
                    className="summary-section-title"
                    sx={{ backgroundColor: GREY_4, color: BLACK_SHADE }}
                  >
                    {que.summaryText}
                  </Card>
                  <Box className="summary-section-item">
                    {getIcon(selectedOption.id, PRIMARY)}
                    <Typography
                      className="summary-section-item-text"
                      sx={{ color: BLACK_SHADE }}
                    >
                      {summaryText}
                    </Typography>
                  </Box>
                </div>
              );
            }
          }
        }
        return null; // Ensure a value is returned
      })}
    </Box>
  );
};

export default Summary;
